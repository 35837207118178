import { render, staticRenderFns } from "./TicketEditor.vue?vue&type=template&id=b65096e0&scoped=true"
import script from "./TicketEditor.vue?vue&type=script&lang=js"
export * from "./TicketEditor.vue?vue&type=script&lang=js"
import style0 from "./TicketEditor.vue?vue&type=style&index=0&id=b65096e0&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b65096e0",
  null
  
)

export default component.exports